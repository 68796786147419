//React
import React from 'react'
import { connect } from 'react-redux'

//Packages
import Helmet from 'react-helmet'
// import Recaptcha from 'react-recaptcha'
import parse from 'url-parse'

//Actions
import { saveSubscribe } from '../../actions/saveSubscribe'

//Components
import Layout from '../../components/layout'
import Loading from '../../components/loading'
import SignalIcon from '../../images/svg/newsletter/piconext-prism-01.svg'

//Icons
import { FaSpinner } from 'react-icons/fa'

//Redux
const mapStateToProps = ({ subSubmitted, subSubmittedData, isLoading }) => {
  return { subSubmitted, subSubmittedData, isLoading }
}
const mapDispatchToProps = (dispatch) => ({
  saveSubscribe: (email, mode, subscription, access) => {
    dispatch(saveSubscribe(email, mode, subscription, access))
  },
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = { recaptchaVerified: false, errorText: '' }
    // this.handleSubmit = this.handleSubmit.bind(this)
  }
  // specifying your onload callback function
  // onLoadCallback = () => {
  //   console.log('Done!!!!')
  // }
  // verifyCallback = response => {
  //   this.setState({ recaptchaVerified: true })
  //   console.log(response)
  // }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      const url = parse(window.location.href, true)
      if (url.query.i && url.query.a && url.query.s) {
        console.log('url.query.i')
        console.log(url.query.i)
        this.props.saveSubscribe(
          url.query.i, //email
          url.query.m ? url.query.m : 'confirm', //mode
          url.query.s, //subscription name
          url.query.a //access key
        )
      }
    }
  }

  // saveSubscribe(email) {
  //   this.props.saveSubscribe(email)
  // }

  // handleUpdate = (event) => {
  //   if (event.target.type === 'checkbox') {
  //     this.setState({
  //       [event.target.name]: event.target.checked,
  //     })
  //   } else {
  //     if (event.target.name === 'unsubEmail') {
  //       this.setState({
  //         [event.target.name]: encodeURIComponent(event.target.value),
  //       })
  //     } else {
  //       this.setState({
  //         [event.target.name]: event.target.value,
  //       })
  //     }
  //   }
  // }

  // handleSubmit() {
  //   if (!this.state.unsubEmail) {
  //     this.setState({ errorText: 'Please enter your e-mail address' })
  //     return
  //   } else {
  //     this.sendUnsub(this.state.unsubEmail)
  //   }
  // }

  render() {
    const { isLoading, subSubmitted, subSubmittedData } = this.props
    let url
    if (typeof window !== `undefined`) {
      url = parse(window.location.href, true)
    }
    let pageMode = 'confirm'
    if (url && url.query && url.query.m && url.query.m === 'unsub') {
      pageMode = 'unsub'
    }
    return (
      <Layout>
        {/* <Helmet>
          <script
            src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`}
            async
            defer
          ></script>
        </Helmet> */}
        <Helmet
          title={`Sidepath - ${pageMode === 'unsub' ? 'Uns' : 'S'}ubscribe`}
        />
        <div class="container container-unsub flex-grow-1">
          <div class="row">
            <div class="col-lg-12">
              <div class="sub-header gradient-callout-eaglequarter p-3 mb-3 round-corners  w-100">
                <SignalIcon />
              </div>
              {subSubmittedData && subSubmittedData.success ? (
                <>
                  <h2>Success</h2>
                  <p class="lead">
                    {pageMode === 'unsub'
                      ? "We've submitted your unsubscription request. We're sorry to see you go!"
                      : "We've confirmed your subscription"}
                  </p>
                </>
              ) : subSubmittedData && subSubmittedData.error ? (
                <>
                  <h2>
                    Confirm {pageMode === 'unsub' ? 'Request' : 'Subscription'}
                  </h2>
                  <p class="lead">
                    {pageMode === 'unsub'
                      ? "We weren't able to process your request. Please contact support@storylava for help"
                      : "We weren't able to confirm your subscription."}
                  </p>
                </>
              ) : (
                <>
                  <h2>
                    Confirm {pageMode === 'unsub' ? 'Request' : 'Subscription'}
                  </h2>
                  <div class="d-flex align-items-start justify-content-start m-4 p-4">
                    <Loading class="small" />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
